<template>
    <div>
        <div>
            <Slider v-if="Lang.SelectedLanguage=='tr'"></Slider>
            <SliderEng v-if="Lang.SelectedLanguage=='en'"></SliderEng>
        </div>
        <div class="container">


            <div class="row ">
                <div class="col-12 col-lg-6 m-t-100">
                    <router-link class="PlusLink" :to="{ name: 'chairman', params: { lang: getlink }}"  v-on:click="window.scrollTo(0, 0)">{{Lang.get('link1')}}</router-link>   
                    <div>
                        <img src="../assets/ykb.png" class="img-fluid" />
                        <div class="Cloud-Text" v-html="Lang.get('text1')"></div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 m-t-100">
                    <router-link :to="{ name: 'ceo', params: { lang: getlink }}" class="PlusLink">{{Lang.get('link2')}}</router-link>     
                    <img src="../assets/ceo.png" class="img-fluid" />
                    <div class="Cloud-Text" v-html="Lang.get('text2')"></div>
                </div>
            </div>


            <div class="row mt-5">
                <div class="col-12 col-lg-6 m-t-100">
                    <router-link :to="{ name: 'financial', params: { lang: getlink }}" class="PlusLink">{{Lang.get('link3')}}</router-link>

                    <div class="text-red Truculenta mt-5 text-18 bold pd5" v-html="Lang.get('text3')">

                    </div>
                    <div class="row d-none d-md-flex"  >

                        <div class="col-6 col-md-4 mb-4">
                            <div class="head1 mb-4 mt-5" v-html="Lang.get('revenue')"></div>
                            <img src="../assets/chart-hasilat.png" />
                        </div>
                        <div class="col-6 col-md-4 mb-4">
                            <div class="head1 mb-4 mt-5" v-html="Lang.get('OperatingProfit')"></div>
                            <img src="../assets/chart-faaliyet-kar.png" />
                        </div>
                        <div class="col-6 col-md-4 mb-4">
                            <div class="head1 mb-4 mt-5" v-html="Lang.get('EBITDAMargin')"></div>

                            <img src="../assets/chat-favor.png" />
                        </div>
                        <div class="col-6 col-md-4 mb-4">
                            <div class="head1 mb-4 mt-5" v-html="Lang.get('ShareholdersEquity')"></div>

                            <img src="../assets/chart-ozkaynak.png" />
                        </div>
                        <div class="col-6 col-md-4 mb-4">
                            <div class="head1 mb-4 mt-5" v-html="Lang.get('GrossProfit')"></div>

                            <img src="../assets/chart-brutkar.png" />
                        </div>
                        <div class="col-6 col-md-4 mb-4">
                            <div class="head1 mb-4 mt-5" v-html="Lang.get('GrossProfitMargin')"></div>

                            <img src="../assets/chart-brutkarmarji.png" />
                        </div>
                    </div>

                    <div class="row d-flex d-md-none"   >
                        <div class="col-6 mb-4">
                            <div class="head1 mb-4 mt-5" v-html="Lang.get('revenue')"></div>
                            <img src="../assets/bc-hasilat.png" class="img-fluid" />
                        </div>
                        <div class="col-6 mb-4">
                            <div class="head1 mb-4 mt-5" v-html="Lang.get('OperatingProfit')"></div>
                            <img src="../assets/bc-esasfkari.png" class="img-fluid" />

                        </div>
                        <div class="col-6 mb-4">
                            <div class="head1 mb-4 mt-5" v-html="Lang.get('EBITDAMargin')"></div>
                            <img src="../assets/bc-favork.png" class="img-fluid" />
                        </div>
                        <div class="col-6 mb-4">
                            <div class="head1 mb-4 mt-5" v-html="Lang.get('ShareholdersEquity')"></div>
                            <img src="../assets/bc-ozkaynaklar.png" class="img-fluid" />
                        </div>
                        <div class="col-6 mb-4">
                            <div class="head1 mb-4 mt-5" v-html="Lang.get('GrossProfit')"></div>

                            <img src="../assets/bc-brutkar.png" class="img-fluid" />
                        </div>
                        <div class="col-6 mb-4">
                            <div class="head1 mb-4 mt-5" v-html="Lang.get('GrossProfitMargin')"></div>

                            <img src="../assets/bc-brutkarmarj.png" class="img-fluid" />
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 m-t-100">
                    <router-link class="PlusLink" :to="{ name: 'pladis', params: { lang: getlink }}">{{Lang.get('link4')}}</router-link>
                
                    <div class="text-red Truculenta mt-5 text-18 bold pd5" v-html="Lang.get('text4')">

                    </div>
                    <div class="row align-items-center">
                        <div class="col mt-5 " style="padding-top:50px;">
                            <div>
                                <img  v-if="Lang.SelectedLanguage=='tr'" src="../assets/map.png" class="img-fluid map-override" />
                                <img  v-if="Lang.SelectedLanguage=='en'" src="../assets/mapEN.png" class="img-fluid map-override" />
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div class="row mt-5">
                <div class="col-12 col-lg-6 m-t-100">
                    <router-link :to="{ name: 'facility', params: { lang: getlink }}" class="PlusLink">{{Lang.get('link5')}}</router-link>  
                    <div class="text-red Truculenta mt-5 text-18 bold pd5" v-html="Lang.get('text5')">

                    </div>
                    <div class="subcont">
                        <img v-if="Lang.SelectedLanguage=='tr'" src="../assets/map-tesisler.svg" class="img-fluid" />
                        <img v-if="Lang.SelectedLanguage=='en'" src="../assets/map-tesislerEN.svg" class="img-fluid" />
                    </div>
                </div>
                <div class="col-12 col-lg-6 m-t-100">
                    <router-link :to="{ name: 'marketing', params: { lang: getlink }}" class="PlusLink">{{Lang.get('link6')}}</router-link>
                   
                    <div class="text-red Truculenta mt-5 text-18 bold pd5" v-html="Lang.get('text6')">

                    </div>
                    <div class="subcont">
                        <img src="../assets/tesisler-img.png" class="img-fluid" />
                    </div>


                </div>
            </div>


            <div class="row mt-5">
                <div class="col-12 col-lg-6 m-t-100">
                    <router-link :to="{ name: 'capacity', params: { lang: getlink }}"class="PlusLink">{{Lang.get('link7')}}</router-link> 
                    <div class="text-red Truculenta mt-5 text-18 bold pd5" v-html="Lang.get('text7')">

                    </div>
                    <div class="subcont">
                        <img src="../assets/uretim-kapasite.png" class="img-fluid" />
                    </div>

                </div>
                <div class="col-12 col-lg-6 m-t-100">
                    <router-link :to="{ name: 'operations', params: { lang: getlink }}" class="PlusLink">{{Lang.get('link8')}}</router-link>
                
                    <div class="text-red Truculenta mt-5 text-18 bold pd5" v-html="Lang.get('text8')">

                    </div>
                    <div class="subcont">
                        <img src="../assets/operasyonel-mukemmellik.png" class="img-fluid" />
                    </div>

                </div>
            </div>

            <div class="row mt-5">
                <div class="col-12 col-lg-6 m-t-100">
                    <router-link :to="{ name: 'investmens', params: { lang: getlink }}" class="PlusLink">{{Lang.get('link9')}}</router-link>  
                    <div class="text-red Truculenta mt-5 text-18 bold pd5" v-html="Lang.get('text9')">

                    </div>
                    <div class="subcont">

                        <img src="../assets/yatirimlar.png" class="img-fluid" />
                    </div>
                </div>
                <div class="col-12 col-lg-6 m-t-100">
                    <router-link :to="{ name: 'sustainability', params: { lang: getlink }}" class="PlusLink">{{Lang.get('link10')}}</router-link>
               
                    <div class="text-green Truculenta mt-5 text-18 bold pd5" v-html="Lang.get('text10')">

                    </div>
                    <div class="subcont">

                        <img src="../assets/surdurulebilirlik.png" class="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  
</template>

<script>
    import lng from "../store/languages";
    import Slider from "../components/Slider.vue";
    import SliderEng from "../components/SliderEng.vue";
export default {
        name: 'Home',
        components: { Slider, SliderEng },
        computed: {
            getlink: function () {
                return lng.SelectedLanguage;
            },
            setStatemenu: function () {
                this.menustate = false;
                window.scrollTo(0, 0);
            }
        },
        data() {
            return {
                Lang: lng,
                perPage: 1,
                slide1active:false
            }
        }
}
</script>
<style>
  
</style>
