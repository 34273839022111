export default {
    SelectedLanguage: "tr",
    keys: {
        "tr": {
            files: {
                header:'pdflink'
            },
            Home: '<strong>ÜLKER BİSKÜVİ</strong> 2020 Özet Faaliyet Raporu',
            link1: 'Yönetim Kurulu Başkanı’nın Mesajı',
            link2: 'CEO’nun Mesajı',
            link3: 'Başlıca Finansal ve Operasyonel Göstergeler',
            link4: 'pladis Hakkında',
            link5: 'Ülker Bisküvi Üretim Tesisleri',
            link6: 'Pazarlama',
            link7: 'Üretim ve Kapasite',
            link8: 'Operasyonel Mükemmellik',
            link9: 'Yatırımlar',
            link10: 'Sürdürülebilirlik Çalışmaları',
            text1: 'Ülker Bisküvi olarak <br/> 2021 yılında da ülkemizin<br/> kalkınma hedeflerine destek <br/> olmayı sürdüreceğiz.',
            text2: '2020 yılında çikolata,<br/> bisküvi ve kek kategorilerinde<br/> toplamda 70’in üzerinde yeni <br/>ürünü piyasaya sunduk.',
            text3: '2020 yılında bisküvi ve çikolata kategorilerinde pazar payını artırarak başarılı finansal ve operasyonel sonuçlara ulaşan Ülker Bisküvi, satış hasılatını 9,4 milyar TL’ye yükseltmiştir.',
            text4: 'pladis, “Her lokmada tüm dünyaya mutluluk vaat ediyoruz” mottosuyla çıktığı global yolculuğuna başarıyla devam etmektedir.',
            text5: ' Ülker Bisküvi 1944 yılında İstanbul Eminönü’nde küçük bir atölyede yalnızca üç işçiyle faaliyetlerine başlamıştır.',
            text6: ' Ülker “söyleyen değil yapan marka olma anlayışıyla”, odağına mutluluğu alarak, başta tüketicileri olmak üzere paydaşlarına fayda sağlamak için çalışmaktadır.',
            text7: 'Ülker Bisküvi, yeni nesil dijital teknolojilerin kullanıldığı yurt içindeki altı fabrikasında, tüketici odaklı inovatif bir anlayışla, en modern ve verimli yöntemlerle mutluluk üretmeye devam etmektedir.',
            text8: 'Operatörden üst yönetime kadar her kademeden çalışan, operasyonel mükemmellik süreçlerinde aktif rol almaktadır.',
            text9: 'Yapılan yatırımlarla müşteri memnuniyetini artırmayı hedefleyen Ülker Bisküvi, böylece ürün kalitesini her geçen gün geliştirmekte ve verimliliği artırarak maliyet tabanını daha da rekabetçi hale getirmektedir.',
            text10: 'Ülker Bisküvi çatısı altındaki sürdürülebilirlik çalışmalarımızı, uzun vadeli değer üretmek; ekonomik, çevresel ve sosyal faktörlerden kaynaklanabilecek risklerin yönetilmesi için gerekli strateji, politika ve hedefleri oluşturmak üzerine odakladık.',
            spot1: 'Dijitalleşme alanında birkaç yıldır oluşturduğumuz altyapının sağladığı güçle salgın sürecini büyük bir başarıyla yönettik.',
            spot2: 'Ülker Bisküvi olarak 2020 yılında faaliyet gösterdiğimiz tüm coğrafyalarda sürdürülebilir büyümemizi sürdürdürürken kârlılığımızı da önemli ölçüde artırdık.',

            revenue: 'Hasılat <small>(Milyon TL)</small>',
            OperatingProfit: 'Esas Faaliyet Kârı <small>(Milyon TL)</small>',
            EBITDAMargin: 'FAVÖK Marjı <small>(Milyon TL)</small>',
            ShareholdersEquity: 'Özkaynaklar <small>(Milyon TL)</small>',
            GrossProfit: 'Brüt Kâr <small>(Milyon TL)</small>',
            GrossProfitMargin: 'Brüt Kâr Marjı <small>(Milyon TL)</small>',
            download: 'İlgili sayfaya gitmek için PDF’ye tıklayın.',
            backhome:"Geri Dönmek İçin Tıklayın"
        },
        "en": {
            files: {
                header: 'pdflinken'
            },
            Home: '<strong>ÜLKER BİSKÜVİ</strong> Annual Review 2020 ',
            link1: 'Message of the Chairman',
            link2: "CEO's Message",
            link3: 'Key Financial and Operational Indicators',
            link4: 'About pladis',
            link5: 'Ülker Biscuit Production Facilities',
            link6: 'Marketing',
            link7: 'Production and Capacity',
            link8: 'Operational Excellence',
            link9: 'Investments',
            link10: 'Sustainability Studies',
            text1: 'At Ülker Bisküvi, we will continue to support our country’s development goals in 2021.',
            text2: 'In the 2020, we launched more than 70 new products in the chocolate, biscuit and cake categories. ',
            text3: 'Ülker Bisküvi reached successful financial and operational results by increasing its market share in the biscuit and chocolate categories, and increased its sales revenue to TL 9.4 billion in 2020.',
            text4: 'pladis operates in 25 plants across 11 countries, with more than 16 thousand employees and deep expertise exceeding 300 years of experience, to bring happiness to the world with every bite to all its consumers by responding to the expectations and needs of the consumers with a rich variety of product range. ',
            text5: 'Ülker Bisküvi began its operations at a small workshop with three employees in Eminönü, Istanbul in 1944.',
            text6: 'Focusing on delivering happiness, Ülker aims to create value for all stakeholders - especially consumers - with the approach of “Actions speak louder than words.”',
            text7: 'Ülker Bisküvi carries on generating happiness at six domestic factories where new generation digital technologies are in use, with an innovative mind-set focused on consumers with the most productive cutting edge techniques. ',
            text8: 'A total of nearly 2 thousand improvements are completed and reported up to the present.The amount of savings achieved during 2020 was TL 21 million. ',
            text9: 'Aiming to increase its customer satisfaction by making investments, Ülker Bisküvi improves product quality more and more and makes its cost structure more competitive by enhancing its operational efficiency.',
            text10: 'We focused our sustainability efforts at Ülker Bisküvi on generating long-term value and establishing the necessary strategies, policies and objectives in order to manage the risks that may arise due to economic, environmental and social factors.',
            spot1: 'We significantly increased our profitability while achieving sustainable growth in our areas of operation in 2021.',
            spot2: 'We place sustainability efforts at the core of our way of business: these efforts are shaped from the ground up and include producers, suppliers, stakeholders, business partners and consumers.',

            revenue: 'Revenue <br/><small>(TL Million)</small>',
            OperatingProfit: 'Operating Profit <br/><small>(TL Million)</small>',
            EBITDAMargin: 'EBITDA Margin <br/><small>(TL Million)</small>',
            ShareholdersEquity: 'Shareholder`s Equity <br/><small>(TL Million)</small>',
            GrossProfit: 'GrossProfit <br/><small>(TL Million)</small>',
            GrossProfitMargin: 'Gross Profit Margin <br/><small>(TL Million)</small>',
            download: 'Download PDF',
            backhome: "Click to back"
        }
    },
    get: function (key) {   
      return this.keys[this.SelectedLanguage][key]
    },
    getpdf: function (key) {
        return this.keys[this.SelectedLanguage]["files"][key]
    }
}