
  <template>
      <div>
          <div class="" style="margin-top:-30px;">
              <carousel :perPage="perPage" :autoplay="autoplay" :autoplayTimeout="autoplayTimeout" :loop="loop" paginationColor="#ffffff" paginationActiveColor="#e91d2d">
                  <slide>
                      <img src="../assets/Slider1/main.png" class="img-fluid desktop" />
                      <img src="../assets/Slider1/main-mobile.png" class="img-fluid mobile" />
                  </slide>
                  <slide>
                     
                      <div class="slide1">
                          <img src="../assets/Slider1/slide1.png" class="img-fl" />
                          <div class="detail">
                              <div class="openner" v-on:click="slide1active=true">+</div>
                              <span class="Truculenta  bold">
                                  İhracat ve Yurt Dışı Operasyonlarımızda   Güçlü Şekilde  Büyümeye
                                  Devam Ettik
                              </span>
                          </div>

                      </div>
                  </slide>
                  <slide>
                      <div class="slide2">
                          <img src="../assets/Slider1/slide2.png" class="img-fl" />
                          <div class="detail">
                              <div class="openner" v-on:click="slide2active=true">+</div>
                              <span class="Truculenta  bold" style="vertical-align:central;display:flex;flex-direction:column;justify-content:center;height:80%;">
                                  Çiftçimizin Yanında Olduk
                              </span>
                          </div>
                      </div>
                  </slide>
                  <slide>
                      <div class="slide3">
                          <img src="../assets/Slider1/slide3.png" class="img-fl" />
                          <div class="detail">
                              <div class="openner" v-on:click="slide3active=true">+</div>
                              <span class="Truculenta  bold">
                                  Tüketicilerimizi Yeni Tatlarla Buluşturduk
                              </span>
                          </div>
                      </div>
                  </slide>
                  <slide>
                      <div class="slide4">
                          <img src="../assets/Slider1/slide4.png" class="img-fl" />
                          <div class="detail">
                              <div class="openner" v-on:click="slide4active=true">+</div>
                              <span class="Truculenta  bold">
                                  İhracat ve Yurt Dışı Operasyonlarımızda   Güçlü Şekilde  Büyümeye
                                  Devam Ettik
                              </span>
                          </div>
                      </div>
                  </slide>
                  <slide>
                      <div class="slide5">
                          <img src="../assets/Slider1/slide5.png" class="img-fl" />
                          <div class="detail">
                              <div class="openner" v-on:click="slide5active=true">+</div>
                              <span class="Truculenta  bold">
                                  Doğayı Emanet
                                  Olarak Gördük
                              </span>
                          </div>
                      </div>
                  </slide>
              </carousel>
          </div>

          <div class="active1" v-if="slide1active">
              <div>
                  <div class="close-active" v-on:click="slide1active=false"></div>
                  <div class="active-caption Truculenta">
                      İhracat ve Yurt Dışı Operasyonlarımızda Güçlü Şekilde Büyümeye Devam Ettik
                  </div>
                  <div class="active-text">
                      Dünyanın bu olağan dışı günlerde küçük mutluluklara ihtiyacı vardı. 100’ün üzerinde ülkeye ihracat yaptık.
                      Suudi Arabistan ve Mısır’da bisküvi pazarındaki
                      liderliğimizi devam ettiriyoruz. Orta Asya ülkelerinde de güçlü büyüme trendimizi sürdürüyoruz.
                  </div>
                  <div class="center">
                      <img src="../assets/Slider1/slide1-content1.png" class="img-fluid" />
                  </div>
              </div>
          </div>

          <div class="active2" v-if="slide2active">
              <div>
                  <div class="close-active" v-on:click="slide2active=false"></div>
                  
                  <div class="center">
                      <img src="../assets/Slider1/slide2-content.png" class="img-fluid" />
                  </div>
              </div>
          </div>

          <div class="active3" v-if="slide3active">
              <div>
                  <div class="close-active" v-on:click="slide3active=false"></div>
                  
                  <div class="center">
                      <img src="../assets/Slider1/slide3-content.png" class="img-fluid" />
                  </div>
              </div>
          </div>

          <div class="active4" v-if="slide4active">
              <div>
                  <div class="close-active" v-on:click="slide4active=false"></div>
               
                  <div class="center">
                      <img src="../assets/Slider1/slide4-content.png" class="img-fluid" />
                  </div>
              </div>
          </div>

          <div class="active5" v-if="slide5active">
              <div>
                  <div class="close-active" v-on:click="slide5active=false"></div>
                  <div class="center">
                      <img src="../assets/Slider1/slide-5-content.png" class="img-fluid" />
                  </div>
              </div>
          </div>
      </div>
      
  </template>

<script>
    import lng from "../store/languages";
    export default {
        name: 'Slider',
        data() {
            return {
                Lang: lng,
                perPage: 1,
                autoplay: true,
                loop:true,
                autoplayTimeout:5000,
                slide1active: false,
                slide5active: false,
                slide4active: false,
                slide3active: false,
                slide2active: false
            }
        }
 
}</script>