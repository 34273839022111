
  <template>
      <div>
          <div class="" style="margin-top:-30px;">
              <carousel :perPage="perPage" :autoplay="autoplay" :autoplayTimeout="autoplayTimeout" :loop="loop" paginationColor="#ffffff" paginationActiveColor="#e91d2d">
                  <slide>
                      <img src="../assets/Slider1/main.png" class="img-fluid desktop" />
                      <img src="../assets/Slider1/main-mobile.png" class="img-fluid mobile" />
                  </slide>
                  <slide>
                      <div class="slide1">
                          <img src="../assets/Slider1/slide1.png" class="img-fl" />
                          <div class="detail">
                              <div class="openner" v-on:click="slide1active=true">+</div>
                              <span class="Truculenta  bold">
                                  We Expanded Our Export and International Operations Strongly

                              </span>
                          </div>

                      </div>
                  </slide>
                  <slide>
                      <div class="slide2">
                          <img src="../assets/Slider1/slide2.png" class="img-fl" />
                          <div class="detail">
                              <div class="openner" v-on:click="slide2active=true">+</div>
                              <span class="Truculenta  bold" style="vertical-align:central;display:flex;flex-direction:column;justify-content:center;height:80%;">
                                  We Supported Our Farmers

                              </span>
                          </div>
                      </div>
                  </slide>
                  <slide>
                      <div class="slide3">
                          <img src="../assets/Slider1/slide3.png" class="img-fl" />
                          <div class="detail">
                              <div class="openner" v-on:click="slide3active=true">+</div>
                              <span class="Truculenta  bold">
                                  We Introduced New Flavors to Our Consumers

                              </span>
                          </div>
                      </div>
                  </slide>
                  <slide>
                      <div class="slide4">
                          <img src="../assets/Slider1/slide4.png" class="img-fl" />
                          <div class="detail">
                              <div class="openner" v-on:click="slide4active=true">+</div>
                              <span class="Truculenta  bold">
                                  We Boosted Production without Waste

                              </span>
                          </div>
                      </div>
                  </slide>
                  <slide>
                      <div class="slide5">
                          <img src="../assets/Slider1/slide5.png" class="img-fl" />
                          <div class="detail">
                              <div class="openner" v-on:click="slide5active=true">+</div>
                              <span class="Truculenta  bold">
                                  We Understand that
                                  Nature is Entrusted to Us

                              </span>
                          </div>
                      </div>
                  </slide>
              </carousel>
          </div>

          <div class="active1" v-if="slide1active">
              <div>
                  <div class="close-active" v-on:click="slide1active=false"></div>
                  <div class="active-caption Truculenta">
                      We Expanded Our Export and International Operations Strongly   
                  </div>
                  <div class="active-text">
                      The world needed a little happiness during these difficult days. We exported our products to over 100 countries. We maintained our leadership position in the biscuit market in Saudi Arabia and Egypt. We also extended our strong growth trend in Central Asian countries.
                  </div>
                  <div class="center">
                      <img src="../assets/Slider1/slide1-content1en.png" class="img-fluid" />
                  </div>
              </div>
          </div>

          <div class="active2" v-if="slide2active">
              <div>
                  <div class="close-active" v-on:click="slide2active=false"></div>
                  
                  <div class="center" style="max-width:550px;margin:auto;">
                      <h1>
                          We Supported Our Farmers

                      </h1>
                      <p>
                          In line with the sustainable development goals of our country, we develop projects that always prioritize local producers in our sustainability activities. With Aliağa Biscuit Wheat, which emerged after many years of our joint work with Bahri Dağdaş International Agricultural Research Institute, we provided high-yielding biscuit wheat that is locally developed and drought-, disease- and climate change-resistant – a first in Turkey. Our wheat, which we harvested for the first time in july 2020, was sowed again in november. Our goal is to meet more than half of our needs from Aliağa Biscuit Wheat in 2023.
                      </p>
                  </div>
              </div>
          </div>

          <div class="active3" v-if="slide3active">
              <div>
                  <div class="close-active" v-on:click="slide3active=false"></div>
                  
                  <div class="center">
                      <img src="../assets/Slider1/slide3-contenten.png" class="img-fluid" />
                  </div>
              </div>
          </div>

          <div class="active4" v-if="slide4active">
              <div>
                  <div class="close-active" v-on:click="slide4active=false"></div>
               
                  <div class="center">
                      <img src="../assets/Slider1/slide4-contenten.png" class="img-fluid" />
                  </div>
              </div>
          </div>

          <div class="active5" v-if="slide5active">
              <div>
                  <div class="close-active" v-on:click="slide5active=false"></div>
                  <div class="center">
                      <img src="../assets/Slider1/slide-5-contenten.png" class="img-fluid" />
                  </div>
              </div>
          </div>
      </div>
      
  </template>

<script>
    import lng from "../store/languages";
    export default {
        name: 'Slider',
        data() {
            return {
                Lang: lng,
                perPage: 1,
                autoplay: true,
                loop:true,
                autoplayTimeout:5000,
                slide1active: false,
                slide5active: false,
                slide4active: false,
                slide3active: false,
                slide2active: false
            }
        }
 
}</script>